import React from "react"
import donate from "../../images/illustrations/donate.svg"
// import learnmore from "../../images/illustrations/learnmore.svg"
import partner from "../../images/illustrations/partner.svg"
import volunteer from "../../images/illustrations/volunteer.svg"
import research from "../../images/illustrations/research.svg"
import { Link } from "gatsby"

import "./get-involved.css"

const GetInvolved = () => (
  <div className="container getinvolved-container">
    <h1> How you can help </h1>
    <div className="getinvolved-contentContainer">
      <div className="getinvolved-item">
        <img src={partner} alt="Partner with us" />
        <p> Corporate sponsorships & advocacy groups </p>
        <Link to="/partner">
          <button className="button getinvolved-button">Partner</button>
        </Link>
      </div>
      <div className="getinvolved-item">
        <img src={donate} alt="Donate" />
        <p> Help the app grow nationwide </p>
        <a
          href="https://donorbox.org/911textapp-nationwide"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="button getinvolved-button">Donate</button>
        </a>
      </div>
      <div className="getinvolved-item">
        <img src={volunteer} alt="Volunteer" />
        <p> Donate your time to help us grow faster </p>
        <a
          href="https://accessos.notion.site/Job-Board-external-4cf4579f919a42228d1d6818b196dadf"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className="button getinvolved-button">Volunteer</button>
        </a>
      </div>
      <div className="getinvolved-item">
        <img src={research} alt="Research" />
        <p> Sign up for research </p>
        {/* TODO: Add link to research form */}
        <Link to="https://docs.google.com/forms/d/e/1FAIpQLSfPjNr0863Poja-a6245bq3IXAWz7gGZV63c1yipXWr2mslNQ/viewform" target="_blank">
          <button className="button getinvolved-button">Participate</button>
        </Link>    
      </div>
    </div>
  </div>
)

export default GetInvolved
